@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #ede9fe;
}

.filter-none {
  filter: none;
}

.filter-grayscale {
  filter: grayscale(100%);
}

.filter-active {
  filter: invert(35%) sepia(92%) saturate(2002%) hue-rotate(240deg)
    brightness(85%) contrast(68%);
}

/* MapComponent.css */
.custom-popup .leaflet-popup-content-wrapper {
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 316px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-popup .leaflet-popup-tip {
  background: #fff;
}

.custom-popup .leaflet-popup-content {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-popup .leaflet-popup-close-button {
  display: none; /* Hide the default close button */
}

.popup-title {
  font-size: 16px;
  font-weight: 400;
  color: #77808b;
  margin-bottom: 5px;
}

.popup-address {
  font-family: "IBM Plex Sans", "sans-serif";
  font-size: 24px;
  font-weight: bold;
  color: #283646;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  padding: 50px;
  border-radius: 10px;
  background-color: #ede9fe8c;
}

::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}

.containerone {
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .containerone {
    overflow-x: auto;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
}

/* CUSTOM customer tab and sidebar responsive */
@media screen and (max-width: 1766px) {
  .table-container {
    height: 435px;
  }
}
@media screen and (max-width: 1375px) {
  .customer-main {
    align-items: center;
    flex-direction: column;
  }
  .filter-container {
    max-width: 1200px !important;
    height: 100vh;
    order: 1;
  }
  .tables-and-map {
    order: 2;
    margin: 0;
  }
}

@media screen and (max-width: 1279px) {
  .table-container {
    height: 339px;
  }
}

@media screen and (max-width: 1375px) {
  .filter-container {
    height: fit-content !important;
  }
}
@media screen and (max-width: 1106px) {
  .sidebar {
    max-width: fit-content;
  }
  .filter-container {
    order: 1;
    width: 100% !important;
  }
  .tables-and-map {
    order: 2;
  }
  .customer-main {
    flex-direction: column;
  }
}

@media screen and (max-width: 439px) {
  .main-container {
    width: 80%;
  }
}
